import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {ApiService} from '../../../core/http/api.service';
import {Location} from '@angular/common';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {CategoryGuide, DocType, Guide} from '../../../models/Guide';
import {MatDialog} from '@angular/material';
import {GuestUpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';
import {M20UpsellDetailsComponent} from '../m20-upsell-details/m20-upsell-details.component';

@Component({
  selector: 'app-m6-upsells',
  templateUrl: './m6-upsells.component.html',
  styleUrls: ['./m6-upsells.component.scss']
})
export class M6UpsellsComponent extends AbstractWindow implements OnInit {

  @Input() embedded: boolean = false;
  currentUpSellOnBoarding: UpSellOnBoarding;
  property: PropertyForGuest;
  corporate: Corporate;
  booking: BookingForGuest;
  upsellsGuides: Guide[] = [];
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              protected dialog: MatDialog,
              private location: Location) {
    super(utilService);
  }


  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        this.currentUpSellOnBoarding = res.propertyAndBooking.upSellOnBoarding;
        //this.utilService.manageCorporateColors(this.corporate);
        if (this.property.guides && this.property.guides.length > 0) {
          this.upsellsGuides = this.property.guides.filter(item => item.upSellSection != null && item.upSellSection.showAsUpSell);
          this.upsellsGuides.sort((a, b) => a.showAsImportantInfoOrder - b.showAsImportantInfoOrder);
        }
      }
    });
  }

  showMobileGuideDetails(guide: Guide) {

    const showUpSellDetails = this.dialog.open(M20UpsellDetailsComponent, {
      width: '100vw',
      height: '100vh',
      autoFocus: false,
      data: {
        property: this.property,
        guide: guide,
        booking: this.booking,
        corporate: this.corporate,
        currentUpSellOnBoarding: this.currentUpSellOnBoarding
      }
    });

    /*const taskCreationDialog = this.dialog.open(GuestGuideDetailsComponent, {
      height: this.isDesktopMode() ? '90%' : '100vh',
      width: this.isDesktopMode() ? '75%': '100vw',
      autoFocus: false,
      data: {
        property: this.property,
        guide: guide,
        booking: this.booking
      }
    });*/

  }

  getGuideCover(guide: Guide) {

    if (guide && guide.guidePictureUrl) {
      return guide.guidePictureUrl;
    }
    if (!guide || !guide.documents || guide.documents.length === 0) {
      return this.getPictureFromCategory(guide.category);
    }
    const firstPhoto = guide.documents.find(item => item.type === DocType.IMAGE);
    if (!firstPhoto) {
      return this.getPictureFromCategory(guide.category);
    }

    return firstPhoto.url;
  }

  getPictureFromCategory(category: string) {
    let image;

    if (!category) {
      return image;
    }
    if (this.corporate && this.corporate.customGuideCategoriesPictures) {
      const found = this.corporate.customGuideCategoriesPictures.find(
        (item) => category === item.categoryName
      );
      if (found && found.url) {
        return found.url;
      }
    }

    switch (category) {
      case CategoryGuide.APPLIANCES:
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
        break;
      case CategoryGuide.CHECKIN:
        image = '../../../../assets/images/list-guides/Checkin/checkin.png';
        break;
      case CategoryGuide.CHECKOUT:
        image = '../../../../assets/images/list-guides/Check-out/checkout.png';
        break;
      case CategoryGuide.ORIENTATION:
        image = '../../../../assets/images/list-guides/Direction/dir.png';
        break;
      case CategoryGuide.RULES:
        image = '../../../../assets/images/list-guides/Rules/img@3x.png';
        break;
      case CategoryGuide.SERVICES:
        image = '../../../../assets/images/list-guides/service.png';
        break;
      case CategoryGuide.WIFI:
        image = '../../../../assets/images/list-guides/Wifi/wifi.png';
        break;
      case CategoryGuide.PARKING:
        image = '../../../../assets/images/list-guides/Parking/parking.png';
        break;
      case CategoryGuide.TRASH:
        image = '../../../../assets/images/list-guides/Trash/trash.png';
        break;
      case CategoryGuide.HEATING:
        image = '../../../../assets/images/list-guides/Heating/heater.png';
        break;
      case CategoryGuide.OTHER:
        image = '../../../../assets/images/list-guides/Other/questions.png';
        break;
      default: {
        image = '../../../../assets/icon/guide/premium-quality.png';
      }
    }
    return image;

  }

  goBack() {
    this.location.back();
  }

  checkIfUpsellItemExists(guideId: string) {
    let check = false;
    if (localStorage.getItem("guestUpSellOnBoarding") != null) {
      const guestUpSellOnBoarding : GuestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
      // console.log("guestUpSellOnBoarding", guestUpSellOnBoarding);
      if (this.booking.id != guestUpSellOnBoarding.propertyBookingId) {
        localStorage.removeItem("guestUpSellOnBoarding");
      } else {
        if (guestUpSellOnBoarding.guides != null) {
          check = guestUpSellOnBoarding.guides.filter( g => g.guideId === guideId).length > 0;
        }
      }
    }
    return check;
  }

  checkUpSellState(guideId: string): string {

    let check = '';
    if (this.currentUpSellOnBoarding != null) {
      if (this.currentUpSellOnBoarding.upSellOnBoardingItems != null && this.currentUpSellOnBoarding.upSellOnBoardingItems.length > 0) {
        this.currentUpSellOnBoarding.upSellOnBoardingItems.forEach( item => {
          if (item.guideId === guideId) {
            check = item.paymentIntentStatus;
          }
        });
      }
    }
    return check;
  }

  partnerScroll(el: any, direction: string) {
    // TODO make a smooth scroll for partners
    el.scrollTo({
      behavior: 'smooth',
      left: direction === 'left' ? el.scrollLeft - 250 : el.scrollLeft + 250, // 200 is the bloc width set on the css
    });
  }

}
