import {Component, Inject, OnInit} from '@angular/core';
import {DocType, Guide} from '../../../models/Guide';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UtilsService} from '../../../core/services/utils.service';
import {Corporate} from '../../../models/guestview/Corporate';
import {GuestGuideEndpoint} from '../../../core/services/V3_GuestGuideEndpoint/GuestGuideEndpoint';
import {RequestForGuide} from '../../../models/GuestGuide/RequestForGuide';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-m22-guide-display',
  templateUrl: './m22-guide-display.component.html',
  styleUrls: ['./m22-guide-display.component.scss']
})
export class M22GuideDisplayComponent implements OnInit {

  guide: Guide;
  currentPictureUrl: string = null;
  corporate: Corporate;
  DocType = DocType;

  constructor(public utilsService: UtilsService,
              private guestGuideEndpoint: GuestGuideEndpoint,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<M22GuideDisplayComponent>) { }

  ngOnInit() {
    if (this.data && this.data.guide) {
      this.guide = this.data.guide;

      let requestForGuide: RequestForGuide = new RequestForGuide();
      requestForGuide.guideId = this.guide.guideId;
      requestForGuide.propertyBookingId = this.data.bookingId;
      requestForGuide.propertyId = this.data.property.id;
      requestForGuide.language = this.translateService.currentLang;

      this.guestGuideEndpoint.getGuideFullTranslation(requestForGuide).subscribe(data => {
        console.log("--> Guide Returned with translation", data);
        this.guide = data;
      });

    }

    if (this.data && this.data.corporate) {
      this.corporate = this.data.corporate;
    }
  }

  getWifiCode() {
    // return "https://www.yaago.com";
    // console.log("--> Wifi Guide", this.guide);
    let returnString = 'WIFI:S:' + this.guide.translations[0].title;
    if (this.guide.translations[0].wifiSecurityProtocol) {
      returnString += ';T:' + this.guide.translations[0].wifiSecurityProtocol;
    }
    if (this.guide.translations[0].description) {
      returnString += ';P:' + this.guide.translations[0].description + ';';
    }
    if (this.guide.translations[0].wifiHidden) {
      returnString += 'H:true;';
    } else {
      returnString += ';';
    }
    // console.log("-->Return String", returnString);
    return returnString;
  }

  choosePicture(pictureUrl : string) {
    if (this.currentPictureUrl === pictureUrl) {
      this.currentPictureUrl = null;
    } else {
      this.currentPictureUrl = pictureUrl;
    }
  }

  back() {
    this.dialogRef.close();
  }

  openLink(url: string) {
    UtilsService.openLinkInNewTab(url);
  }

  getDocumentName(document) {
    return document.substring(document.lastIndexOf('/') + 1);
  }



}
