import { NgModule } from '@angular/core';
import {
  MatDialogModule, MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSliderModule
} from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { DesktopGuestBookingDetailsComponent } from './guest-booking-details/desktop-guest-booking-details/desktop-guest-booking-details.component';
import { GuestBookingDetailsComponent } from './guest-booking-details/guest-booking-details.component';
import { GuestBookingSetupComponent } from './guest-booking-setup/guest-booking-setup.component';
import { GuestFooterPageComponent } from './guest-footer-page/guest-footer-page.component';
import { GuestGalleryDetailMobileComponent } from './guest-gallery-detail-mobile/guest-gallery-detail-mobile.component';
import { GuestGuideDetailComponent } from './guest-guide-detail/guest-guide-detail.component';
import { GuestGuidePhotoDetailComponent } from './guest-guide-photo-detail/guest-guide-photo-detail.component';
import { GuestGuidesDetailDesktopComponent } from './guest-guides-detail-desktop/guest-guides-detail-desktop.component';
import { ImagesCarouselComponent } from './guest-guides-detail-desktop/images-carousel/images-carousel.component';
import { GuestHostServicesComponent } from './guest-host-services/guest-host-services.component';
import { GuestHygieneComponent } from './guest-hygiene/guest-hygiene.component';
import { Yaagov2HygieneComponent } from './guest-hygiene/yaagov2-hygiene/yaagov2-hygiene.component';
import { GuestLandingPageComponent } from './g1-guest-landing-page/guest-landing-page.component';
import { GuestLoginComponent } from './guest-login/guest-login.component';
import { GuestMenuDesktopComponent } from './g0-guest-menu-desktop/guest-menu-desktop.component';
import { GuestMenuMobileComponent } from './g0-guest-menu-mobile/guest-menu-mobile.component';
import { GuestPropertyGalleryComponent } from './guest-property-gallery/guest-property-gallery.component';
import { GuestPropertyGuidesDesktopComponent } from './guest-property-guides/desktop/guest-property-guides-desktop.component';
import { GuestPropertyGuidesComponent } from './guest-property-guides/guest-property-guides.component';
import { GuestRecommendationV2MiniCardComponent } from './guest-recommendations/guest-recommendation-v2-mini-card/guest-recommendation-v2-mini-card.component';
import { GuestRecommendationsCardDesktopComponent } from './guest-recommendations/guest-recommendations-card/guest-recommendations-card-desktop/guest-recommendations-card-desktop.component';
import { GuestRecommendationsCardMobileComponent } from './guest-recommendations/guest-recommendations-card/guest-recommendations-card-mobile/guest-recommendations-card-mobile.component';
import { GuestRecommendationsCardComponent } from './guest-recommendations/guest-recommendations-card/guest-recommendations-card.component';
import { GuestRecommendationsMapDesktopComponent } from './guest-recommendations/guest-recommendations-map/guest-recommendations-map-desktop/guest-recommendations-map-desktop.component';
import { GuestRecommendationsMapMobileComponent } from './guest-recommendations/guest-recommendations-map/guest-recommendations-map-mobile/guest-recommendations-map-mobile.component';
import { GuestRecommendationsMapComponent } from './guest-recommendations/guest-recommendations-map/guest-recommendations-map.component';
import { GuestRecommendationsReviewsDesktopComponent } from './guest-recommendations/guest-recommendations-reviews/guest-recommendations-reviews-desktop/guest-recommendations-reviews-desktop.component';
import { GuestRecommendationsReviewsMobileComponent } from './guest-recommendations/guest-recommendations-reviews/guest-recommendations-reviews-mobile/guest-recommendations-reviews-mobile.component';
import { GuestRecommendationsReviewsComponent } from './guest-recommendations/guest-recommendations-reviews/guest-recommendations-reviews.component';
import { GuestRecommendationsViewDesktopComponent } from './guest-recommendations/guest-recommendations-view/guest-recommendations-view-desktop/guest-recommendations-view-desktop.component';
import { GuestRecommendationsViewComponent } from './guest-recommendations/guest-recommendations-view/guest-recommendations-view.component';
import { GuestRecommendationsComponent } from './guest-recommendations/guest-recommendations.component';
import { RecommendationsCreateComponent } from './guest-recommendations/recommendations-create/recommendations-create.component';
import { GuestReviewTeamamteComponent } from './guest-review/guest-review-teamamte/guest-review-teamamte.component';
import { GuestReviewComponent } from './guest-review/guest-review.component';
import { GuestRoutingModule } from './guest-routing.module';
import { GuestToppageDesktopComponent } from './guest-toppage/desktop/guest-toppage-desktop.component';
import { GuestToppageComponent } from './guest-toppage/guest-toppage.component';
import { GuestUsefulnumberFullListDesktopComponent } from './guest-usefulnumber-full-list-desktop/guest-usefulnumber-full-list-desktop.component';
import { CardNumberDesktopComponent } from './guest-usefulnumber/card-number-desktop/card-number-desktop.component';
import { CardNumberMobileComponent } from './guest-usefulnumber/card-number-mobile/card-number-mobile.component';
import { DesktopGuestUsefulnumberComponent } from './guest-usefulnumber/desktop-guest-usefulnumber/desktop-guest-usefulnumber.component';
import { GuestUsefulnumberComponent } from './guest-usefulnumber/guest-usefulnumber.component';
import { SliderComponent } from './slider/slider.component';
import { StarRatingComponent } from './star-raiting/star-rating.component';
import {MatRippleModule} from '@angular/material/core';
import {GuestGuideDetailsComponent} from './guest-guides/guest-guide-details/guest-guide-details.component';
import { GuestInventoryComponent } from './guest-inventory/guest-inventory.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {DeletePhotoComponent} from './guest-inventory/delete-photo/delete-photo.component';
import { GuestMessagingComponent } from './guest-messaging/guest-messaging.component';
import { FooterBookletReviewsComponent } from './footer-booklet-reviews/footer-booklet-reviews.component';
import { UpsaleCategoryListComponent } from './upsale/upsale-category-list/upsale-category-list.component';
import { UpsaleSelectorComponent } from './upsale/upsale-selector/upsale-selector.component';
import { UpsaleBasketComponent } from './upsale/upsale-basket/upsale-basket.component';
import {MatRadioModule} from '@angular/material/radio';
import { GuestArrivedLeftComponent } from './guest-arrived-left/guest-arrived-left.component';
import { MobileGuestArrivedLeftComponent } from './guest-arrived-left/mobile-guest-arrived_left/mobile-guest-arrived-left.component';
import { DesktopGuestArrivedLeftComponent } from './guest-arrived-left/desktop-guest-arrived_left/desktop-guest-arrived-left.component';
import { GuestRecoWidgetComponent } from './guest-recommendations/guest-reco-widget/guest-reco-widget.component';
import {  LivretWelcomeComponent } from './guest-welcome-livret/guest-welcome-livret.component';
import {Y3ChatMessagingComponent} from './y3-chat-messaging/y3-chat-messaging.component';
import {Y3HeaderComponent} from './y3-header/y3-header.component';
import {Y3UpsellsGuideComponent} from './y3-upsells-guide/y3-upsells-guide.component';
import {Y3PlaformServicesComponent} from './y3-plaform-services/y3-plaform-services.component';
import { G4BookingDetailsComponent } from './g4-booking-details/g4-booking-details.component';
import {Y3PreCheckinComponent} from './y3-pre-checkin/y3-pre-checkin.component';
import { G10PreCheckInComponent } from './g10-pre-check-in/g10-pre-check-in.component';
import { G11OnlineCheckInComponent } from './g11-online-check-in/g11-online-check-in.component';
import { M0LandingPageComponent } from './m0-landing-page/m0-landing-page.component';
import { M1PropertyDetailsComponent } from './m1-property-details/m1-property-details.component';
import { M2RecommendationTop4Component } from './m2-recommendation-top4/m2-recommendation-top4.component';
import { M3GuidesTopComponent } from './m3-guides-top/m3-guides-top.component';
import { M4ContactDetailsComponent } from './m4-contact-details/m4-contact-details.component';
import { M6UpsellsComponent } from './m6-upsells/m6-upsells.component';
import { M5HygienComponent } from './m5-hygien/m5-hygien.component';
import { M7ChatComponent } from './m7-chat/m7-chat.component';
import { M8ImportantGuidesComponent } from './m8-important-guides/m8-important-guides.component';
import { M9NumbersComponent } from './m9-numbers/m9-numbers.component';
import {M10InventoryComponent} from './m10-inventory/m10-inventory.component';
import { M11PlatformServicesComponent } from './m11-platform-services/m11-platform-services.component';
import { M101InventoryItemComponent } from './m10-inventory/m101-inventory-item/m101-inventory-item.component';
import { M12PasswordScreenComponent } from './m12-password-screen/m12-password-screen.component';
import { M13WelcomeScreenComponent } from './m13-welcome-screen/m13-welcome-screen.component';
import { M14BookletReviewComponent } from './m14-booklet-review/m14-booklet-review.component';
import { M15ImportantGuideTopComponent } from './m15-important-guide-top/m15-important-guide-top.component';
import { M16ImportantGuideTopComponent } from './m16-important-guide-top/m16-important-guide-top.component';
import {Y3BookingDetailsComponent} from "./y3-booking-details/y3-booking-details.component";
import {Y3BookletReviewComponent} from "./y3-booklet-review/y3-booklet-review.component";
import {Y3CheckinComponent} from "./y3-checkin/y3-checkin.component";
import {Y3ItemTitleComponent} from "./y3-item-title/y3-item-title.component";
import {Y3LandingGuidesComponent} from "./y3-landing-guides/y3-landing-guides.component";
import {Y3LandingRecommendationComponent} from "./y3-landing-recommendation/y3-landing-recommendation.component";
import {Y3UsefulnumberComponent} from "./y3-usefulnumber/y3-usefulnumber.component";
import {Y3ImportantGuideComponent} from "./y3-important-guide/y3-important-guide.component";
import { M20UpsellDetailsComponent } from './m20-upsell-details/m20-upsell-details.component';
import { M201UpsellCardContentComponent } from './m20-upsell-details/m201-upsell-card-content/m201-upsell-card-content.component';
import { M21RecommendationCardComponent } from './m21-recommendation-card/m21-recommendation-card.component';
import { M22GuideDisplayComponent } from './m22-guide-display/m22-guide-display.component';

@NgModule({
  declarations: [
    GuestLoginComponent,
    GuestBookingDetailsComponent,
    GuestLandingPageComponent,
    GuestHostServicesComponent,
    DesktopGuestBookingDetailsComponent,
    MobileGuestArrivedLeftComponent,
    DesktopGuestArrivedLeftComponent,
    GuestPropertyGuidesComponent,
    GuestUsefulnumberComponent,
    GuestHygieneComponent,
    GuestToppageComponent,
    GuestFooterPageComponent,
    DesktopGuestUsefulnumberComponent,
    GuestToppageDesktopComponent,
    GuestMenuDesktopComponent,
    GuestPropertyGuidesDesktopComponent,
    GuestGuidesDetailDesktopComponent,
    ImagesCarouselComponent,
    GuestGuidePhotoDetailComponent,
    GuestUsefulnumberFullListDesktopComponent,
    RecommendationsCreateComponent,
    GuestGalleryDetailMobileComponent,
    GuestReviewComponent,
    GuestPropertyGalleryComponent,
    GuestReviewTeamamteComponent,
    GuestRecommendationsMapComponent,
    GuestRecommendationsMapDesktopComponent,
    GuestRecommendationsMapMobileComponent,
    GuestRecommendationsCardComponent,
    GuestRecommendationsCardDesktopComponent,
    GuestRecommendationsCardMobileComponent,
    GuestRecommendationsReviewsComponent,
    GuestRecommendationsReviewsDesktopComponent,
    GuestRecommendationsReviewsMobileComponent,
    SliderComponent,
    GuestRecommendationsViewComponent,
    GuestRecommendationsViewDesktopComponent,
    GuestMenuMobileComponent,
    GuestBookingSetupComponent,
    GuestGuideDetailComponent,
    GuestRecommendationsComponent,
    CardNumberDesktopComponent,
    CardNumberMobileComponent,
    StarRatingComponent,
    Yaagov2HygieneComponent,
    GuestGuideDetailsComponent,
    GuestRecommendationV2MiniCardComponent,
    GuestInventoryComponent,
    DeletePhotoComponent,
    GuestMessagingComponent,
    FooterBookletReviewsComponent,
    UpsaleCategoryListComponent,
    UpsaleSelectorComponent,
    UpsaleBasketComponent,
    GuestArrivedLeftComponent,
    GuestRecoWidgetComponent,
    LivretWelcomeComponent,
    Y3HeaderComponent,
    Y3ChatMessagingComponent,
    Y3UpsellsGuideComponent,
    Y3PlaformServicesComponent,
    Y3BookingDetailsComponent,
    Y3BookletReviewComponent,
    Y3CheckinComponent,
    Y3ItemTitleComponent,
    Y3LandingGuidesComponent,
    Y3LandingRecommendationComponent,
    Y3ImportantGuideComponent,
    Y3UsefulnumberComponent,
    G4BookingDetailsComponent,
    Y3PreCheckinComponent,
    G10PreCheckInComponent,
    G11OnlineCheckInComponent,
    M0LandingPageComponent,
    M1PropertyDetailsComponent,
    M2RecommendationTop4Component,
    M3GuidesTopComponent,
    M4ContactDetailsComponent,
    M6UpsellsComponent,
    M5HygienComponent,
    M7ChatComponent,
    M8ImportantGuidesComponent,
    M9NumbersComponent,
    M10InventoryComponent,
    M11PlatformServicesComponent,
    M101InventoryItemComponent,
    M12PasswordScreenComponent,
    M13WelcomeScreenComponent,
    M14BookletReviewComponent,
    M15ImportantGuideTopComponent,
    M16ImportantGuideTopComponent,
    M20UpsellDetailsComponent,
    M201UpsellCardContentComponent,
    M21RecommendationCardComponent,
    M22GuideDisplayComponent
  ],
    imports: [
        SharedModule,
        GuestRoutingModule,
        SharedPipesModule,
        CarouselModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
        MatSliderModule,
        MatSelectModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        MatDividerModule,
        MatExpansionModule,
        NgxMaterialTimepickerModule,
        MatCheckboxModule,
        MatRippleModule,
        PerfectScrollbarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        // WelcomeLivretModule
    ],
  entryComponents: [DeletePhotoComponent, GuestGuideDetailsComponent, M20UpsellDetailsComponent, M21RecommendationCardComponent, M22GuideDisplayComponent],
    exports: [
        GuestMenuDesktopComponent,
        GuestMenuMobileComponent,
        GuestRecommendationsCardDesktopComponent
    ]

})
export class GuestModule {
}
