<div class="m22-page-container">

  <img *ngIf="currentPictureUrl == null" class="m22-image-container" src="{{utilsService.getGuideCover(guide, corporate)}}">
  <img *ngIf="currentPictureUrl != null" class="m22-image-container" src="{{currentPictureUrl}}">

  <div class="m22-return-container">
    <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-center" >
      <div class="m22-mat" (click)="back()">arrow_back</div>
    </div>
  </div>

  <div class="m22-card-container">
    <div class="y3-bold y3-Large">{{guide.translations[0].title}}</div>


    <div *ngIf="guide.textBeforePictures" class="y3-margin-top-16">
      <div class="y3-Small y3-bold" *ngIf="guide.category === 'wifi'" >{{'guest-guid-page.password' | translate}} :</div>
      <div class="y3-Small y3-color-scorpion m22-guide-description" [innerHTML]="guide.translations[0].description"></div>
    </div>

    <div class="y3-margin-top-16">
      <div *ngFor="let singleVideo of guide.videos" class="y3-flex-column">

        <video controls *ngIf="singleVideo.type === DocType.VIDEO || singleVideo.type === DocType.EXTERNAL_VIDEO"  class="m22-video">
          <source src="{{singleVideo.url + '#t=0.001'}}">
          Your browser does not support the video tag.
        </video>

        <app-external-video-box *ngIf="[DocType.YOUTUBE, DocType.VIMEO, DocType.DAILYMOTION].includes(singleVideo.type)"
                                [videoUrl]="singleVideo.url"
        ></app-external-video-box>


      </div>
    </div>

    <section id="SECTION____GALERIE____IMAGE" *ngIf="guide.documents != null && guide.documents?.length > 0" class="y3-margin-top-16">
      <div class="y3-bold y3-Small">{{'m22-guide-display.more-pictures' | translate}}</div>
      <perfect-scrollbar class="y3-flex-row" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
        <div class="y3-flex-column y3-flex-align-center y3-margin-top-16 ">
          <div *ngFor="let doc of guide.documents" >

            <img *ngIf="doc.type === DocType.IMAGE && utilsService.getGuideCover(guide, corporate) !== doc.url" class="m22-image-size y3-clickable" src="{{doc.url}}" >
            <!--<iframe *ngIf="doc.type === DocType.PDF"  class="m22-image-size" [src]="doc.url | safe2:'resourceUrl'" ></iframe>-->
          </div>

          <div *ngFor="let doc of guide.documents" >
            <div *ngIf="doc.type === DocType.PDF">
              <a href="{{doc.url}}" target="_blank">{{getDocumentName(doc.url)}}</a>
            </div>

            <div *ngIf="doc.type === DocType.WORD">
              <a href="{{doc.url}}" target="_blank">{{getDocumentName(doc.url)}}</a>
            </div>
            <!--<iframe *ngIf="doc.type === DocType.WORD" frameborder="0" [src]="('https://view.officeapps.live.com/op/embed.aspx?src=' + doc.url) |safe2 : 'resourceUrl'"></iframe>-->
          </div>

        </div>
      </perfect-scrollbar>
    </section>

    <div class="y3-flex-column y3-margin-top-18" *ngIf="guide?.externalLinks && guide?.externalLinks?.length > 0">
      <div class="y3-bold y3-Small">{{'guest-guid-page.external-links' | translate}} </div>
      <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-margin-top-8" *ngFor="let link of guide.externalLinks; let i = index">
        <span  class=" roboto y3-bold y3-Small underline clickable txt-nowrap txt-ellipsis hidden-overflow" style="word-wrap: break-word; width: 100%" (click)="openLink(link.url)">{{link.title}}</span>
      </div>
    </div>

    <div *ngIf="!guide.textBeforePictures" class="y3-margin-top-16">
      <div class="y3-Small y3-bold" *ngIf="guide.category === 'wifi'" >{{'guest-guid-page.password' | translate}} :</div>
      <div class="y3-Small y3-color-scorpion m22-guide-description" [innerHTML]="guide.translations[0].description"></div>
    </div>

    <!--<div *ngIf="guide.category === 'wifi'" >
      <app-qr-code [isCopyVisible]="false" url="{{getWifiCode()}}"></app-qr-code>
    </div>-->

    <div class="y3-empty-space"></div>

  </div>




</div>
