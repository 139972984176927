import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractWindow } from 'src/app/core/abstract/AbstractWindow';
import { GuestService } from 'src/app/core/services/guest.service';
import { InternalPropertyService } from 'src/app/core/services/internal.property.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Corporate, HomePageSettings } from 'src/app/models/guestview/Corporate';
import { GuestServiceIntern } from '../guest.service';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';

@Component({
  selector: 'app-guest-welcome-livret',
  templateUrl: './guest-welcome-livret.component.html',
  styleUrls: ['./guest-welcome-livret.component.scss']
})
export class LivretWelcomeComponent extends AbstractWindow implements OnInit, AfterViewInit {
  corporate: Corporate;
  homeSettings : any;
  screenWidth: number;
  propertyDetails: PropertyForGuest ;
  bookingId : string;
  year = new Date().getFullYear();
  bookingNull : boolean;
  propertyId : string;
  fortest : any;
  urlLang: string;
  constructor(
    public propertyService: InternalPropertyService,
    public utilService: UtilsService,
    public route: ActivatedRoute,
    public guestService: GuestService,
    public router: Router,
    private internGuestService: GuestServiceIntern,
    private translateService: TranslateService,
    //private routeSnapshot: ActivatedRouteSnapshot

  ) {
  super(utilService);
   }

   ngAfterViewInit() {
     this.route.queryParams
       .subscribe(params => {
         console.log('LAAAAAAAAAANG', params.lang);
           this.urlLang = params.lang;
         console.log('LAAAAAAAAAANG2',  this.urlLang);
         }
       );
   }

  ngOnInit() {

    this.route.data.subscribe((res) => {
      if (res && res.propertyAndBooking) {

      }
    });
    /*if (this.routeSnapshot.children[0].queryParams.lang) {
      this.urlLang = this.routeSnapshot.children[0].queryParams.lang;
    }*/
     this.internGuestService.getBookingForGuest().subscribe(res => {
      console.log('this.globalfortest', res)
      if (res != null){
        this.bookingNull = false

      }
     })
    const corporate = this.propertyService.getCurrentCorporate();
    if (corporate){
      this.homeSettings = corporate.homePageSettings;
      console.log("this.homeSettings",this.homeSettings)
      //this.utilsService.manageCorporateColors(corporate);

    }
      this.propertyDetails =  this.propertyService.localProperty;
      this.propertyId = this.propertyService.localProperty.id;
      console.log("this.propertyId",this.propertyId)
      this.route.paramMap.subscribe( ParamMap => {
      this.bookingId = ParamMap.get('bookingId');
      });



  }
  navigateToLivret(){
    //add for test
    GuestService.welcomeWasClosed = true;
    if ( this.bookingNull === false ){
      this.guestService
      .getPropertyAndBookingForGuestWelcomeSeen(this.bookingId)
      .subscribe((res) => {
        if (res.booking.welcomePageSeen === true){
          if (this.urlLang) {
            this.router.navigate(['/guest/' + this.bookingId], {queryParams: {lang: this.urlLang}}).then();
           } else {
            this.router.navigate(['/guest/' + this.bookingId]).then() ;
          }
         }
      }
    );
  } else {
    this.navigateToPass();
  }
  }
  navigateToPass(){
    if (this.urlLang) {
      this.router.navigate(['/pwd/'+this.propertyId+'/ask4pwd'], { queryParams: { lang: this.urlLang } });
    } else {
      this.router.navigate(['/pwd/'+this.propertyId+'/ask4pwd']);
    }
  }
}
