<div class="y3-divider y3-margin-bottom-32 y3-margin-top-16">
  <div class="y3-container " >


    <mat-icon class="material-symbols-outlined y3-steph-title-dot">cottage</mat-icon>
    <div class="y3-steph-title">{{'guest-view-booking-details.booking-details' | translate}}<span class="y3-steph-title-dot">.</span></div>

    <!--<div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div *ngIf="!section" style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{'guest-view-booking-details.booking-details' | translate}}" [type]="'banner'"></app-tag>
      </div>
      <div *ngIf="section" style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{section.sectionTitle}}" [type]="'banner'"></app-tag>

      </div>
    </div>-->

    <div>
      <p *ngIf="property?.description"  [innerHTML]="property.description"
         class="y3-steph-color-text property-description roboto Medium txt-color-scorpion margin-top-16 margin-bottom-16
                   {{(property?.description && property?.description.length > 300 && !showingMoreDescription) ? 'description-max-height' : ''}}">
      </p>

      <span class="y3-steph-color-text y3-roboto y3-Small y3-bold y3-underline txt-color-grvogue y3-flex-responsive y3-flex-justify-content-center y3-clickable y3-margin-top-8"
            *ngIf="property?.description && property?.description.length > 300"
            (click)="showingMoreDescription = !showingMoreDescription">
            {{(showingMoreDescription ? 'guest-view-booking-details.show-less' :'guest-view-booking-details.show-more') | translate }}
      </span>

      <!--<app-audio-play *ngIf="this.property?.description && !property?.noAudioDisplay" [textToSpeak]="utilsService.getCleanTextToRead(property.description)"></app-audio-play>-->
    </div>


    <div class="y3-two-colum y3-margin-top-24 " *ngIf="property.poi">

        <div class="map-wrapper">
          <section>
          <div class="map-container">
            <app-leaflet-map
              [mapId]="'y3-mapDetailDesktop'"
              [defaultZoom]="15"
              [centerOfTheMap]="centerOfTheMap"
              [corporate]="corporate"
              [propertyPoi]="propertyPoi"
              [whereIamPoi]=""
              [properties]=""
              [language]="language"
              [showAllProperties]="true"
              [showRecoCorporate]="true"
              [showProperty]="true"
              [showRecoYaago]="true"
              [showRecoInfluencer]="true"
              [showRecoTourism]="true"
              [showOwner]="true"
              [showFilterReco]="false"
              [recapVersion]="'v0'"
              [showZoomControl]="false"
              [showChecks]="false"
              [relatedPoiForDistanceCalculation]="propertyPoi"
            ></app-leaflet-map>
          </div>
          <!--<div #gMap class="map-container">
          </div>-->
          <div class="y3-flex-row y3-flex-justify-content-end">
            <div class="y3-booking-details-find">
              <app-button [type]="'steph-button-blue'" [text]="'guest-detail-booking.find-direction' | translate"
                          (debounceClick)="findDirection()"
              ></app-button></div>
          </div>

          </section>
        </div>

      <div class="map-wrapper map-wrapper-2">
        <section id ="">
          <!--CO-HOST IN CHARGE-INFOS-->

          <mat-icon class="material-symbols-outlined y3-steph-title-dot">account_circle</mat-icon>
          <div class="y3-steph-title y3-margin-bottom-8">{{('guest-view-booking-details.' + property.coHostInCharge?.coHostLabel) | translate}}<span class="y3-steph-title-dot">.</span></div>

          <!--<div *ngIf="property.coHostInCharge && property.showMeAsHost" class="y3-margin-bottom-24 y3-roboto" >
            <app-tag  text="  {{('guest-view-booking-details.' + property.coHostInCharge?.coHostLabel) | translate}}" [type]="'banner'"></app-tag>
          </div>-->

          <div *ngIf="property.coHostInCharge && property.showMeAsHost " class="card y3-padding-12 person-container y3-margin-bottom-16">
            <div class="picture-container">
              <img *ngIf="property.coHostInCharge?.pictureUrl" src="{{property.coHostInCharge.pictureUrl}}" alt="picture">
              <span *ngIf="!property.coHostInCharge?.pictureUrl" class="y3-roboto y3-Large picture-placeholder">
                {{utilsService.getFirstLetter(property.coHostInCharge)}}
              </span>
              <div class="language-container y3-margin-top-8">
                <ng-template [ngIf]="property.coHostInCharge?.languages && property.coHostInCharge.languages.length <= 2">
                  <img *ngFor="let languageCode of property.coHostInCharge?.languages" class="margin-right-8 margin-top-8" src="{{ getLanguage(languageCode) }}" alt="flags">
                </ng-template>
                <ng-template [ngIf]="property.coHostInCharge?.languages && property.coHostInCharge.languages.length > 2">
                  <app-tooltip [position]="'right'">
                    <div class="flex-row flex-align-center" placeholder>
                      <img style="width: 17px; height: 17px;" src="/assets/icon/icon_world.svg" alt="world">
                      <span class="y3-roboto y3-Medium y3-bold txt-color-grvogue y3-margin-left-4">({{property.coHostInCharge?.languages?.length}})</span>
                    </div>
                    <div class="flex-row flex-align-center y3-flex-justify-center" content>
                      <img *ngFor="let languageCode of property.coHostInCharge?.languages" class="margin-left-12 margin-right-12 " src="{{ getLanguage(languageCode) }}" alt="flags">
                    </div>
                  </app-tooltip>
                </ng-template>
              </div>
            </div>
            <div class="infos-container y3-margin-left-24" *ngIf="property.coHostInCharge && property.showMeAsHost ">
              <p *ngIf="property.coHostInCharge?.companyName" class="y3-roboto y3-Large txt-color-grvogue">{{property.coHostInCharge?.companyName }} </p>
              <span class="y3-roboto y3-Large txt-color-grvogue">{{property.coHostInCharge?.firstName }} {{property.coHostInCharge?.lastName }}</span>
              <p class="y3-margin-top-16 y3-roboto y3-X-small txt-color-dimgray" *ngIf="property.coHostInCharge?.email"
                 (click)="emailPerson(property.coHostInCharge)">
                <img class="y3-margin-right-8" src="/assets/icon/icon_message.svg" alt="mail">
                {{property.coHostInCharge?.email}}
              </p>

              <a class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="property.coHostInCharge?.whatsAppLink"
                 href="{{property.coHostInCharge?.whatsAppLink}}" target="_blank">
                <img class="margin-right-8" style="height: 16px;" src="/assets/icon/icon-whatsapp.svg" alt="whatsapp">
                <span style="text-decoration: underline; font-weight: bold; color: #03224C">WhatsApp Chat</span>
              </a>

              <p class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="property.coHostInCharge?.phoneNumber"
                 (click)="callTeammate(property.coHostInCharge)">
                <img class="y3-margin-right-8" src="/assets/icon/icon_tel.svg" alt="phone">
                {{property.coHostInCharge?.phoneNumber}} <span *ngIf="property.coHostInCharge?.phoneUrgencyOnly">&nbsp; {{'guest-view-booking-details.urgency-only' | translate}}</span>
              </p>

              <p class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="property.coHostInCharge?.fullAddress">
                {{property.coHostInCharge?.fullAddress}} </p>

              <p class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="property.coHostInCharge?.openHours">
                {{property.coHostInCharge?.openHours}} </p>

              <div class="media-container">




                <a class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property.coHostInCharge?.instagramUrl"
                   href="{{getCorrectLink(property.coHostInCharge?.instagramUrl)}}" target="_blank">
                  <div class="y3-flex-row y3-flex-align-center">
                    <img  class="icon-social" src="assets/icon/Instagram_icon.png">
                    <!--<div>{{corporate?.instagramUrl}}</div>-->
                  </div>
                </a>


                <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property.coHostInCharge?.linkedInUrl"
                   href="{{getCorrectLink(property.coHostInCharge?.linkedInUrl)}}" target="_blank">
                  <div class="y3-flex-row y3-flex-align-center">
                    <img  class="icon-social" src="assets/icon/linkedin-bleu-icon.svg">
                    <!--<div style="text-decoration: underline; font-weight: bold; color: #03224C">{{corporate?.linkedInUrl}}</div>-->
                  </div>
                </a>


                <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property.coHostInCharge?.facebookUrl"
                   href="{{getCorrectLink(property.coHostInCharge?.facebookUrl)}}" target="_blank">
                  <div class="y3-flex-row y3-flex-align-center">
                    <img class="icon-social" src="assets/icon/facebook_icon.png">
                    <!--<div>{{corporate?.facebookUrl}}</div>-->
                  </div>
                </a>


                <a class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="property.coHostInCharge?.webSiteUrl"
                   href="{{getCorrectLink(property.coHostInCharge?.webSiteUrl)}}" target="_blank">
                  <div class="y3-flex-row y3-flex-align-center">
                    <div style="text-decoration: underline; font-weight: bold; color: #03224C">{{property.coHostInCharge?.webSiteUrl}}</div>
                  </div>
                </a>

              </div>

            </div>

          </div>

          <mat-icon class="material-symbols-outlined y3-steph-title-dot">bed</mat-icon>
          <div class="y3-steph-title y3-margin-bottom-8">{{'guest-view-booking-details.disposition' | translate}}<span class="y3-steph-title-dot">.</span></div>


          <!--<div *ngIf="(!property?.noshowBedsDetail) && (property.doubleBeds + property.singleBeds + property.sofaBeds + property.bathrooms) >0  " class="y3-margin-top-24">
            <app-tag text="{{'guest-view-booking-details.disposition' | translate}}" [type]="'banner'"></app-tag>
          </div>-->

          <div *ngIf="(!property?.host?.btobInfo || !property?.noshowBedsDetail) && (property.doubleBeds + property.singleBeds + property.sofaBeds + property.bathrooms) >0  " class="card y3-flex-column y3-roboto y3-Small y3-margin-top-8">
            <div class="y3-margin-top-4" *ngIf="property.doubleBeds > 0">{{counter('doublebeds')}} {{counter('doublebeds') > 1 ? ('guest-detail-booking.doublebeds' | translate) : ('guest-detail-booking.doublebed' | translate) }}</div>
            <div class="y3-margin-top-4" *ngIf="property.singleBeds > 0">{{counter('singlebeds')}} {{counter('singlebeds') > 1 ? ('guest-detail-booking.singlebeds' | translate) : ('guest-detail-booking.singlebed' | translate)}}</div>
            <div class="y3-margin-top-4" *ngIf="property.sofaBeds > 0">{{counter('sofabeds')}} {{counter('sofabeds') > 1 ? ('guest-detail-booking.sofabeds' | translate) : ('guest-detail-booking.sofabed' | translate)}}</div>
            <div class="y3-margin-top-4" *ngIf="property.bathrooms > 0">{{counter('baths')}} {{counter('baths') > 1 ? ('guest-detail-booking.bathRooms' | translate) : ('guest-detail-booking.bathRoom' | translate)}}</div>
          </div>
        </section>
      </div>
    </div>



  </div>
</div>
