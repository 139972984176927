import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {SharedSnackbarService} from '../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../../shared/components/shared-snackbar/models/snackbar-message';
import {HttpErrorResponse} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {CategoryGuide, DocType, Guide} from '../../models/Guide';
import {BookingForGuest} from '../../models/guestview/BookingForGuest';
import {PersonForGuest} from '../../models/guestview/PersonForGuest';
import {CoHostInCharge, PropertyForGuest} from '../../models/guestview/PropertyForGuest';
import {Corporate} from "../../models/guestview/Corporate";
import { Subject } from 'rxjs';
import {DocumentUploadTypes} from '../../models/YaagoDocument';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private translateService: TranslateService,
              private snackbarService: SharedSnackbarService,
              private  sanitizer: DomSanitizer) {
  }

  allowedImagesDocs = DocumentUploadTypes.images().concat(DocumentUploadTypes.pdf());
  allowVideosDocs = DocumentUploadTypes.videos();

  static readonly languageArray: string[] = ['fr', 'en', 'de', 'es', 'it', 'nl', 'da', 'pt', 'ja',  'no', 'pl',  'ru', 'sv', 'tr', 'zh'];

  static readonly mapFlag: Map<string, string> = new Map([
      ['English', '../../../../assets/flags/english-flag.png'],
      ['en', '../../../../assets/flags/english-flag.png'],
      ['Français',  '../../../../assets/flags/french-flag.png'],
      ['fr',  '../../../../assets/flags/french-flag.png'],
      ['no',  '../../../../assets/flags/norwegian-flag.png'],
      ['Español', '../../../../assets/flags/spanish-flag.png'],
      ['es', '../../../../assets/flags/spanish-flag.png'],
      ['Italian', '../../../../assets/flags/italian-flag.png'],
      ['it', '../../../../assets/flags/italian-flag.png'],
      ['Deutsch', '../../../../assets/flags/deutsch-flag.png'],
      ['de', '../../../../assets/flags/deutsch-flag.png'],
      ['Portuguese', '../../../../assets/flags/portuguese-flag.png'],
      ['pt', '../../../../assets/flags/portuguese-flag.png'],
      ['中文', '../../../../assets/flags/china-flag.jpg'],
      ['zh', '../../../../assets/flags/china-flag.jpg'],
      ['Magyar', '../../../../assets/flags/hungary-flag.jpg'],
      ['hu', '../../../../assets/flags/hungary-flag.jpg'],
      ['Polski', '../../../../assets/flags/poland-flag.jpg'],
      ['pl', '../../../../assets/flags/poland-flag.jpg'],
      ['ru', '../../../../assets/flags/russia-flag.jpg'],

      ['Dansk', '../../../../assets/flags/danemark-flag.png'],
      ['da', '../../../../assets/flags/danemark-flag.png'],
      ['Norsk', '../../../../assets/flags/norway-flag.png'],
      ['no', '../../../../assets/flags/norway-flag.png'],
      ['Svenska', '../../../../assets/flags/sweden-flag.png'],
      ['sv', '../../../../assets/flags/sweden-flag.png'],
      ['tr', '../../../../assets/flags/Turkey.svg'],
      ['nl', '../../../../assets/flags/nederland.jpeg'],
      ['ja', '../../../../assets/flags/japan-flag.jpg'],
      ['us', '../../../../assets/flags/american-flag.png'],
      ['br', '../../../../assets/flags/brazil.png'],
      ['ca', '../../../../assets/flags/canadian.jpg'],
      ['Русский', '../../../../assets/flags/russia-flag.jpg']
    ]
  );

  readonly mapFlagNonStatic: Map<string, string> = new Map([
      ['English', '../../../../assets/flags/english-flag.png'],
      ['en', '../../../../assets/flags/english-flag.png'],
      ['Français',  '../../../../assets/flags/french-flag.png'],
      ['fr',  '../../../../assets/flags/french-flag.png'],
      ['no',  '../../../../assets/flags/norwegian-flag.png'],
      ['Español', '../../../../assets/flags/spanish-flag.png'],
      ['es', '../../../../assets/flags/spanish-flag.png'],
      ['Italian', '../../../../assets/flags/italian-flag.png'],
      ['it', '../../../../assets/flags/italian-flag.png'],
      ['Deutsch', '../../../../assets/flags/deutsch-flag.png'],
      ['de', '../../../../assets/flags/deutsch-flag.png'],
      ['Portuguese', '../../../../assets/flags/portuguese-flag.png'],
      ['pt', '../../../../assets/flags/portuguese-flag.png'],
      ['中文', '../../../../assets/flags/china-flag.jpg'],
      ['zh', '../../../../assets/flags/china-flag.jpg'],
      ['Magyar', '../../../../assets/flags/hungary-flag.jpg'],
      ['hu', '../../../../assets/flags/hungary-flag.jpg'],
      ['Polski', '../../../../assets/flags/poland-flag.jpg'],
      ['pl', '../../../../assets/flags/poland-flag.jpg'],
      ['ru', '../../../../assets/flags/russia-flag.jpg'],

      ['Dansk', '../../../../assets/flags/danemark-flag.png'],
      ['da', '../../../../assets/flags/danemark-flag.png'],
      ['Norsk', '../../../../assets/flags/norway-flag.png'],
      ['no', '../../../../assets/flags/norway-flag.png'],
      ['Svenska', '../../../../assets/flags/sweden-flag.png'],
      ['sv', '../../../../assets/flags/sweden-flag.png'],
      ['tr', '../../../../assets/flags/Turkey.svg'],
      ['nl', '../../../../assets/flags/nederland.jpeg'],
      ['ja', '../../../../assets/flags/japan-flag.jpg'],
      ['us', '../../../../assets/flags/american-flag.png'],
      ['br', '../../../../assets/flags/brazil.png'],
      ['ca', '../../../../assets/flags/canadian.jpg'],
      ['Русский', '../../../../assets/flags/russia-flag.jpg']
    ]
  );
  bookingTransferSub :Subject<any> = new Subject<any>()

  public static isLanguageSupported(guestLanguage: string): string {
    for (const i18nlanguage of this.languageArray) {
      console.log("i18nLangaue", i18nlanguage.toLocaleLowerCase());
      // console.log("guestLanguage", guestLanguage.toLocaleLowerCase());
      if (guestLanguage){
      if (i18nlanguage.toLowerCase().indexOf(guestLanguage.toLocaleLowerCase()) >= 0) {
        return i18nlanguage;
      }
    }
    }
    return "en";
  }


  static formatDate(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date && date !== '') {
      ret = moment(date).format('DD, MMM');
    }
    return ret;
  }

  static formatDateWithLocale(date: string, locale: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date && date !== '') {
      ret = moment(date).locale(locale).format('DD, MMM');
    }
    return ret;
  }

  static getLanguageFlag(code: string) {
    return this.mapFlag.get(code);
  }

  getLanguageFlag(code: string) {
    return this.mapFlagNonStatic.get(code);
  }

  static getNonTranslatedLanguageName( languageCode: string) {
    switch (languageCode) {
      case 'fr':
        return 'Français';
      case 'en':
        return 'English';
      case 'us':
        return 'American';
      case 'de':
        return 'Deutsch';
      case 'es':
        return 'Español';
      case 'it':
        return 'Italiano';
      case 'nl':
        return 'Nederlands';
      case 'pt':
        return 'português';
      case 'da':
        return 'Dansk';
      case 'no':
        return 'Norsk';
      case 'ru':
        return 'русский';
      case 'pl':
        return 'Polskie';
      case 'sv':
        return 'Svenska';
      case 'tr':
        return 'Türk';
      case 'zh':
        return '中国人';
      case 'br':
        return 'Brazil';
      case 'ja':
        return '日本';
      case 'ca':
        return 'Canadian';
    }
  }

  static openLinkInNewTab(url: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    if (!this.isSafari()) {
      a.target = '_blank';
    }
    a.click();
    document.body.removeChild(a);
  }
  static isSafari(): boolean {
    return (
      window.navigator.userAgent.search('Safari') >= 0 &&
      window.navigator.userAgent.search('Chrome') < 0
    );
  }
  public manageCorporateColors(corporate: Corporate) {
    if (corporate != null) {

      if (corporate.yaagoColorTitle) {
        document.documentElement.style.setProperty('--yaago-color-title', corporate.yaagoColorTitle);
      }

      if (corporate.yaagoColorSubTitle) {
        document.documentElement.style.setProperty('--yaago-color-sub-title', corporate.yaagoColorSubTitle);
      }

      if (corporate.yaagoColorPrimaryButton) {
        document.documentElement.style.setProperty('--yaago-color-primary-button', corporate.yaagoColorPrimaryButton);
      }

      if (corporate.yaagoColorSecondaryButton) {
        document.documentElement.style.setProperty('--yaago-color-secondary-button', corporate.yaagoColorSecondaryButton);
      }

      if (corporate.yaagoColorUploadButton) {
        document.documentElement.style.setProperty('--yaago-color-upload-button', corporate.yaagoColorUploadButton);
      }

      if (corporate.yaagoColorButton) {
        document.documentElement.style.setProperty('--yaago-color-button', corporate.yaagoColorButton);
      }
      if (corporate.yaagoColorFontButton) {
        document.documentElement.style.setProperty('--yaago-color-font-button', corporate.yaagoColorFontButton);
      }

      if (corporate.yaagoColorDesktopBanner) {
        document.documentElement.style.setProperty('--yaago-color-desktop-bandeau', corporate.yaagoColorDesktopBanner);
      }

      if (corporate.yaagoColorDesktopBannerFont) {
        document.documentElement.style.setProperty('--yaago-color-desktop-bandeau-font', corporate.yaagoColorDesktopBannerFont);
      }

      if (corporate.yaagoColorDesktopBannerButton) {
        document.documentElement.style.setProperty('--yaago-color-desktop-bandeau-button', corporate.yaagoColorDesktopBannerButton);
      }

      if (corporate.yaagoColorDesktopSection) {
        document.documentElement.style.setProperty('--yaago-color-desktop-section', corporate.yaagoColorDesktopSection);
      }

      if (corporate.yaagoColorDesktopSectionFont) {
        document.documentElement.style.setProperty('--yaago-color-desktop-section-font', corporate.yaagoColorDesktopSectionFont);
      }


      if (corporate.yaagoColorSectionGuides) {
        document.documentElement.style.setProperty( '--yaago-color-section-guides', corporate.yaagoColorSectionGuides);
      }
      if (corporate.yaagoColorSectionHygien) {
        document.documentElement.style.setProperty( '--yaago-color-section-hygien', corporate.yaagoColorSectionHygien);
      }
      if (corporate.yaagoColorSectionRecommendations) {
        document.documentElement.style.setProperty( '--yaago-color-section-recommendations', corporate.yaagoColorSectionRecommendations);
      }
      if (corporate.yaagoColorSectionTop) {
        document.documentElement.style.setProperty( '--yaago-color-section-top', corporate.yaagoColorSectionTop);
      }
      if (corporate.yaagoColorSectionImportant) {
        document.documentElement.style.setProperty( '--yaago-color-section-important', corporate.yaagoColorSectionImportant);
      }
      if (corporate.yaagoColorSectionUpSells) {
        document.documentElement.style.setProperty( '--yaago-color-section-upsells', corporate.yaagoColorSectionUpSells);
      }
      if (corporate.yaagoColorSectionBookletReview) {
        document.documentElement.style.setProperty( '--yaago-color-section-bookletreviews', corporate.yaagoColorSectionBookletReview);
      }
      if (corporate.yaagoColorSectionPlatformServices) {
        document.documentElement.style.setProperty( '--yaago-color-section-platformservices', corporate.yaagoColorSectionPlatformServices);
      }
      if (corporate.yaagoColorSectionUsefullNumbers) {
        document.documentElement.style.setProperty( '--yaago-color-section-usefulnumbers', corporate.yaagoColorSectionUsefullNumbers);
      }
      if (corporate.yaagoRadiusButtonMobile >= 1) {
        document.documentElement.style.setProperty( '--yaago-color-radius-button', corporate.yaagoRadiusButtonMobile + 'px');
      }
      if (corporate.opacityBurgerMenu) {
        document.documentElement.style.setProperty( '--yaago-opeacity-burger-menu', '1');
      }


      if (corporate.primaryColor) {
        document.documentElement.style.setProperty('--dunnessant-color', corporate.primaryColor);
      }
      if (corporate.forgetMeNotColor) {
        document.documentElement.style.setProperty('--forgetMeNot-color', corporate.forgetMeNotColor);
      }
      if (corporate.greenVogueColor) {
        document.documentElement.style.setProperty('--greenVogue-color', corporate.greenVogueColor);
      }
      if (corporate.bookletButtonColor) {
        document.documentElement.style.setProperty('--button-color', corporate.bookletButtonColor);
      } else {
        document.documentElement.style.setProperty('--button-color', "#03224C");
      }
      if (corporate.catskillColor) {
        document.documentElement.style.setProperty('--catSkill-color', corporate.catskillColor);
      }

    }
  }

  isDesktop(windowWidth: number): boolean {
    return windowWidth >= 1024;
  }
  isDesktopForMenu(windowWidth: number): boolean {
    return windowWidth >= 1300;
  }

  handleYaagoErrorCode(error: HttpErrorResponse) {
    if (error.status === 400) {
      this.snackbarService.push(new SnackbarMessage(
        this.translateService.instant('yaago-error-code.' + error.headers.get('yaago-error-code')), 'error'));
    } else {
      this.snackbarService.push(new SnackbarMessage(error.message, 'error'));
    }
  }

  displayDistance(distance: number) {
    // console.log(distance);
    if (distance > 0) {
      return distance.toFixed(2) + ' km';
    } else {
      return (distance * 100) + ' m';
    }
  }

  isYoutubeUrl(url: string): boolean {
    return (url.toLowerCase().indexOf('youtube', 0) >= 0);
  }

  groupByKey(array, key) {
    // Return the end result
    if (array) {
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, []); // empty object is the initial value for result object
    }
  }

  groupByKey2(array, key, key2) {
    // Return the end result
    if (array) {
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object

        if (currentValue[key2] !=null && currentValue[key2] !== '') {
          (result[currentValue[key2]] = result[currentValue[key2]] || []).push(
            currentValue
          );
        } else {

          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
        }
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, []); // empty object is the initial value for result object
    }
  }

  sanitizeVideoUrl(url: string): SafeResourceUrl {
    if (url && url.trim() !== '') {
      /*const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*!/;
      const match = url.match(regExp);*/
      const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const vimeoRegex = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
      const dailyMotionRegex = /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/i;

      const matchYoutube = url.match(youtubeRegExp);
      const matchVimeo = url.match(vimeoRegex);
      const matchDailyMotion = url.match(dailyMotionRegex);

      if (matchYoutube && matchYoutube[2].length === 11) {
        url = url.replace(url, 'https://www.youtube.com/embed/' + matchYoutube[2]);
      }

      if (matchVimeo && matchVimeo[1].length > 0) {
        url = url.replace(url, 'https://player.vimeo.com/video/' + matchVimeo[1]);
      }

      if (matchDailyMotion && matchDailyMotion[2].length > 0) {
        url = url.replace(url, 'https://www.dailymotion.com/embed/video/' + matchDailyMotion[2]);
      }
      // if (url.startsWith('https://youtu.be/')) {
      //   url = url.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
      // } else {
      //   url = url.replace('watch?v=', 'embed/');
      // }
      // this.currentTranslation.video = url;
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  videoUrlType(url: string): DocType {
    if (url == null || url.trim() === '') {
      return null;
    }
    const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const vimeoRegex = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
    const dailyMotionRegex = /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/i;

    const matchYoutube = url.match(youtubeRegExp);
    const matchVimeo = url.match(vimeoRegex);
    const matchDailyMotion = url.match(dailyMotionRegex);

    if (matchYoutube && matchYoutube[2].length === 11) {
      return DocType.YOUTUBE;
    }

    if (url.indexOf("youtube") > 0) {
      console.log("YOUTUBE");
      return DocType.YOUTUBE;
    }

    //console.log('VIMEO-MATCH', matchVimeo);
    if (matchVimeo && matchVimeo[1].length > 0) {
      return DocType.VIMEO;
    }

    //console.log('DailyMotion-MATCH', matchDailyMotion);
    if (matchDailyMotion && matchDailyMotion[2].length > 0) {
      return DocType.DAILYMOTION;
    }


    return DocType.EXTERNAL_VIDEO;
  }

  getTheKeysCode(propertyBooking: BookingForGuest): string {
    let retCode = '';
    if (propertyBooking.theKeysAccesses) {
      propertyBooking.theKeysAccesses.forEach( access => {
        if (access.accessoryType === 'Digicode') {
          retCode = access.code;
        }
      });
      return retCode;
    }
  }

  getTheKeysLink(propertyBooking: BookingForGuest): string {
    let retCode = '';
    if (propertyBooking.theKeysAccesses) {
      propertyBooking.theKeysAccesses.forEach( access => {
        if (access.accessoryType === 'Gateway') {
          retCode = access.code;
        }
      });
    }
    return retCode;
  }

  getCleanTextToRead(desc: string) {
    if (!desc) {
      return;
    }
    let textToSpeech = desc.replace(/<[^>]*>/g, ' ');
    textToSpeech = textToSpeech.replace(/&nbsp;/gi, ' ');
    // textToSpeech = textToSpeech.replace(/^https?:\/\/.*[\r\n]*/g, ' ');
    return textToSpeech;
  }

  formatDateTime(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('DD/MM/YYYY HH:mm');
    }
    return ret;
  }

  getFirstLetter(host: PersonForGuest | CoHostInCharge) {
    if (!host) {
      return '';
    }
    if (host.firstName) {
      return host.firstName.substr(0, 1);
    }
    if (host.lastName) {
      return host.lastName.substr(0, 1);
    }
    if (host.email) {
      return host.email.substr(0, 1);
    }
  }

  openToPosition(distGps) {
    UtilsService.openLinkInNewTab('https://www.google.com/maps?q=' + distGps);
  }
  getDirection(currentGps, distGps) {
    UtilsService.openLinkInNewTab('https://www.google.com/maps/dir/' + currentGps + '/' + distGps);
  }

  getTime(date: string, defaultDate: string) {
    if (date) {
      return moment(date, 'hh:mm:ss').format('HH:mm').replace(':', 'h');
    } else if (defaultDate) {
      return (moment(defaultDate, 'hh:mm:ss').format('HH:mm')).replace(':', 'h');
    } else {
      return this.translateService.instant('guest-detail-booking.set-time');
    }
  }

  public getLanguageNameFromCode(code: string): string {
    return this.translateService.instant('language.' + code);
  }

  formatDate(date: string): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      moment.locale(this.translateService.currentLang);
      ret = moment(date).format('dddd DD/MM/YYYY @ HH:mm:ss');
    }
    return ret;
  }

  getMainPhoto(propertyForGuest: PropertyForGuest): string {
    let returnphoto = '';
    if (propertyForGuest && propertyForGuest.photos.length > 0) {
      propertyForGuest.photos.forEach( p => {
        if (p.main) {
          returnphoto = p.url.replace('aki_policy=medium', '');
        }
      });

      if (returnphoto === '' && propertyForGuest.photos.length > 0) {
        returnphoto =  propertyForGuest.photos[0].url.replace('aki_policy=medium', '');
      }
    }
    return returnphoto;
  }

  newRemoveFromIterable(it: any[], value: any): any[] {
    console.log('try to remove value', value);
    let newIt: any[] = [];
    it.forEach( item => {
      if (item !== value) {
        newIt.push(item);
      } else {
        console.log('found value', value);
      }
    });
    console.log('new array', newIt);
    return newIt;
  }

  findDirection(property: PropertyForGuest) {
    const distGps = property.poi.y + ',' + property.poi.x;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;

        const currentGps = lat + ',' + long;
        this.getDirection(currentGps, distGps);
      }, err => {
        this.openToPosition(distGps);
      });
    } else {
      this.openToPosition(distGps);
    }
  }
  getDocumentName(document) {
    return document.substring(document.lastIndexOf('/') + 1);
  }

  getGuideCover(guide: Guide, corporate: Corporate) {

    if (guide && guide.guidePictureUrl) {
      return guide.guidePictureUrl;
    }
    if (!guide || !guide.documents || guide.documents.length === 0) {
      return this.getPictureFromCategory(guide.category, corporate);
    }
    const firstPhoto = guide.documents.find(item => item.type === DocType.IMAGE);
    if (!firstPhoto) {
      return this.getPictureFromCategory(guide.category, corporate);
    }

    return firstPhoto.url;
  }

  getPictureFromCategory(category: string, corporate: Corporate) {
    let image;

    if (!category) {
      return image;
    }
    if (corporate && corporate.customGuideCategoriesPictures) {
      const found = corporate.customGuideCategoriesPictures.find(
        (item) => category === item.categoryName
      );
      if (found && found.url) {
        return found.url;
      }
    }

    switch (category) {
      case CategoryGuide.APPLIANCES:
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
        break;
      case CategoryGuide.CHECKIN:
        image = '../../../../assets/images/list-guides/Checkin/checkin.png';
        break;
      case CategoryGuide.CHECKOUT:
        image = '../../../../assets/images/list-guides/Check-out/checkout.png';
        break;
      case CategoryGuide.ORIENTATION:
        image = '../../../../assets/images/list-guides/Direction/dir.png';
        break;
      case CategoryGuide.RULES:
        image = '../../../../assets/images/list-guides/Rules/img@3x.png';
        break;
      case CategoryGuide.SERVICES:
        image = '../../../../assets/images/list-guides/service.png';
        break;
      case CategoryGuide.WIFI:
        image = '../../../../assets/images/list-guides/Wifi/wifi.png';
        break;
      case CategoryGuide.PARKING:
        image = '../../../../assets/images/list-guides/Parking/parking.png';
        break;
      case CategoryGuide.TRASH:
        image = '../../../../assets/images/list-guides/Trash/trash.png';
        break;
      case CategoryGuide.HEATING:
        image = '../../../../assets/images/list-guides/Heating/heater.png';
        break;
      case CategoryGuide.OTHER:
        image = '../../../../assets/images/list-guides/Other/questions.png';
        break;
      default: {
        image = '../../../../assets/icon/guide/premium-quality.png';
      }
    }
    return image;

  }

  formatIgloohomeCode(code: string): string {
    const newCode = code.substr(0,3) + '-' + code.substr(3,3) + '-' + code.substr(6,3);
    return newCode;
  }


}
