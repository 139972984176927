import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {GuestService} from '../../../core/services/guest.service';
import {InternalPropertyService} from '../../../core/services/internal.property.service';
import {Photo} from '../../../models/Photo';
import {UtilsService} from '../../../core/services/utils.service';
import {ImageService} from '../../../core/services/image.service';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {PlatformAffiliateServices} from '../../../models/guestview/PlatformAffiliateServices';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {GuestServiceIntern} from '../guest.service';
import {LanguageManagementService} from '../../../core/services/language.service';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {BookletReviews} from '../../../models/BookletReviews';
import {Corporate} from "../../../models/guestview/Corporate";
import {LoaderService} from "../../../core/services/LoaderService";
import {UpsaleCategoryAndProductDto} from "../../../models/guestview/upsale/UpsaleCategoryAndProductDto";
import {ApiService} from "../../../core/http/api.service";
import {Title} from '@angular/platform-browser';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';


@Component({
  selector: 'app-g1-guest-landing-page',
  templateUrl: './guest-landing-page.component.html',
  styleUrls: ['./guest-landing-page.component.scss']
})
export class GuestLandingPageComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit, AfterContentChecked  {

  sections= ['sectionToppage', 'sectionImportantGuide', 'sectionGuestDetails',
    'sectionGuide', 'sectionRecommendation', 'sectionUsefull', //'sectionUpsales',
    'sectionServices', 'sectionHygien', 'sectionReview'];

  favIcon: HTMLLinkElement = document.querySelector('#favicon');

  public landingPageFragment = '';
  private onDestroy = new Subject();

  @ViewChild('gMap', null) gmapElement: any;
  @ViewChild('container', null) gcontainerElement: any;
  @Output() scrollevent = new EventEmitter<boolean>();
  public map: any;
  urlLang: string;
  screenWidth: number;
  hasUpsales: boolean;
  mainPropertyPhoto: Photo = null;
  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  currentUpSellOnBoarding: UpSellOnBoarding;
  bookletReviews: BookletReviews;
  platformAffiliateServices: PlatformAffiliateServices[];
  upsaleCategoryAndProductDtos: UpsaleCategoryAndProductDto[] = [];

  bookingId = '';

  lat: number;
  long: number;

  constructor(public guestService: GuestService,
              public languageService: LanguageManagementService,
              public internGuestService: GuestServiceIntern,
              public imageService: ImageService,
              private apiService: ApiService,
              private title: Title,
              public router: Router,
              public route: ActivatedRoute,
              public propertyService: InternalPropertyService,
              public translateService: TranslateService,
              public utilService: UtilsService,
              public snackbarService: SharedSnackbarService,
              private loaderService: LoaderService,
              @Inject(DOCUMENT) private documentNew: Document) {
    this.getScreenWidth();
  }

  ngOnInit() {


    window.scroll(0, 0);

    this.loaderService.dismissLoader();

    this.snackbarService.dismiss();
    this.route.queryParamMap.subscribe(params => {
        if (params[`params`].lang) {
          this.urlLang = params[`params`].lang ;
          console.log("---> language :", this.urlLang);
        }
    });

    this.route.params.subscribe( param => {
      if (param.bookingId) {
        this.bookingId = param.bookingId;
      }
    });

    this.route.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      this.getBookingDetails(res, this.urlLang);
    });
  }

  ngAfterContentChecked() {

  }

  ngAfterViewInit() {
    if (this.router.url.indexOf('/guides') > 0) {
      this.landingPageFragment = "guides";
      console.log("LastRouterGuide", this.router.url.substring(this.router.url.lastIndexOf("/") + 1));
      if (this.router.url.substring(this.router.url.lastIndexOf("/") + 1) != 'guide') {
        this.internGuestService.setCurrentGuideId( this.router.url.substring(this.router.url.lastIndexOf("/") + 1));
      }
    } else if (this.router.url.indexOf('/recommendations') > 0) {
      console.log("------> find fragment reco");
      this.landingPageFragment = "recommendations";
    } else if (this.router.url.indexOf('/details') > 0) {
      console.log("------> find fragment details");
      this.landingPageFragment = "details";
    } else if (this.router.url.indexOf('/usefulnumber') > 0) {
      console.log("------> find fragment usefulnumber");
      this.landingPageFragment = "usefullnumber";
    } else if (this.router.url.indexOf('/services') > 0) {
      this.landingPageFragment = "services";
    } else if (this.router.url.indexOf('/upsells') > 0) {
      this.landingPageFragment = "upsells";
    } else if (this.router.url.indexOf('/hygienes') > 0) {
      this.landingPageFragment = "hygienes";
    } else if (this.router.url.indexOf('/messaging') > 0) {
      this.landingPageFragment = "messaging";
    }
    if (this.landingPageFragment && this.landingPageFragment !== '') {
      const element = document.querySelector("#" + this.landingPageFragment);
      if (element) {

        element.scrollIntoView(true);
      }
    }
  }
  getBookingDetails(res, lang: string) {
    if (res && res.propertyAndBooking) {


      this.property = res.propertyAndBooking.property;
      this.guestService.sendProperty(this.property);
      this.mainPropertyPhoto = this.propertyService.getMainPhotoUrl();
      this.booking = res.propertyAndBooking.booking;
      this.bookletReviews = res.propertyAndBooking.bookletReviews;
      this.guestService.sendBooking(this.booking);
      this.corporate = res.propertyAndBooking.corporate;
      this.guestService.sendCorporate(this.corporate);
      this.currentUpSellOnBoarding = res.propertyAndBooking.upSellOnBoarding;

      if (this.corporate != null && this.corporate.favLogoUrl != null && this.corporate.favLogoUrl !== '' ) {
        this.favIcon.href = this.corporate.favLogoUrl;
        if (this.corporate.bookletBrowserTitle != null) {
          this.title.setTitle(this.corporate.bookletBrowserTitle);
        } else {
          this.title.setTitle(this.corporate.name);
        }
      }

      this.hasUpsales = res.propertyAndBooking.hasUpsales;

      this.guestService.sendupsaleCategoryAndProductDtos(res.propertyAndBooking.upsaleCategoryAndProductDtos);

      // CUSTOMISATION DES COULEURS
      // this.utilService.manageCorporateColors(this.corporate);

      let bookletLanguage = this.urlLang;
      if (bookletLanguage == null) {
        this.urlLang = this.translateService.getBrowserLang();
        if (this.booking && this.booking.guests != null && this.booking.guests.length > 0
          && this.booking.guests[0].languages != null && this.booking.guests[0].languages.length > 0) {
          bookletLanguage = this.booking.guests[0].languages[0];
        }
        if (this.booking == null) {
          bookletLanguage = this.translateService.getBrowserLang();
        }
      }

      bookletLanguage = UtilsService.isLanguageSupported(bookletLanguage);
      this.languageService.setLanguageInUrl(bookletLanguage);
      this.translateService.use(bookletLanguage);

      this.platformAffiliateServices = res.propertyAndBooking.platformAffiliateServices;
      this.platformAffiliateServices.reverse();



      if (this.property.messageBirdWidgetId != null && this.property.messageBirdWidgetId != '') {
        let script = document.createElement('script');
        script.text = " var MessageBirdChatWidgetSettings = { \n" +
          "    widgetId: '" + this.property.messageBirdWidgetId +  "', \n" +
          "    initializeOnLoad: true, \n" +
          "  };\n" +
          "\n" +
          "  !function(){\"use strict\";if(Boolean(document.getElementById(\"live-chat-widget-script\")))console.error(\"MessageBirdChatWidget: Snippet loaded twice on page\");else{var e,t;window.MessageBirdChatWidget={},window.MessageBirdChatWidget.queue=[];for(var i=[\"init\",\"setConfig\",\"toggleChat\",\"identify\",\"hide\",\"on\",\"shutdown\"],n=function(){var e=i[d];window.MessageBirdChatWidget[e]=function(){for(var t=arguments.length,i=new Array(t),n=0;n<t;n++)i[n]=arguments[n];window.MessageBirdChatWidget.queue.push([[e,i]])}},d=0;d<i.length;d++)n();var a=(null===(e=window)||void 0===e||null===(t=e.MessageBirdChatWidgetSettings)||void 0===t?void 0:t.widgetId)||\"\",o=function(){var e,t=document.createElement(\"script\");t.type=\"text/javascript\",t.src=\"https://livechat.messagebird.com/bootstrap.js?widgetId=\".concat(a),t.async=!0,t.id=\"live-chat-widget-script\";var i=document.getElementsByTagName(\"script\")[0];null==i||null===(e=i.parentNode)||void 0===e||e.insertBefore(t,i)};\"complete\"===document.readyState?o():window.attachEvent?window.attachEvent(\"onload\",o):window.addEventListener(\"load\",o,!1)}}();\n";
        document.body.appendChild(script);
      }

    } else {
      this.router.navigate(['/misc/lost']);
    }
  }


  loadScript() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;

    chatScript.src = "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js";
    document.body.appendChild(chatScript);

  }

  scrolled(e) {
    this.scrollevent.emit(true);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
   this.ngOnInit();

  }

  isDesktopMode(): boolean {
    return this.utilService.isDesktop(this.screenWidth);
  }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
  }

}


