import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from './components/button/button.component';
import {
  MatBadgeModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
} from '@angular/material';
import {SharedSnackbarComponent} from './components/shared-snackbar/shared-snackbar.component';
import {TranslateModule} from '@ngx-translate/core';
import {GuestGalleryDetailComponent} from './components/guest-gallery-detail/guest-gallery-detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {AmenitiesCounterComponent} from './components/amenities-counter/amenities-counter.component';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {ClipboardModule} from 'ngx-clipboard';
import { AudioPlayComponent } from './components/audio-play/audio-play.component';
import { CotoonFooterComponent } from './components/footers/cotoon-footer/cotoon-footer.component';
import {TabsMenuComponent} from './components/tabs-menu/tabs-menu.component';
import {ExternalVideoBoxComponent} from "./components/external-video-box/external-video-box.component";
import {QrCodeComponent} from "./components/qr-code/qr-code.component";
import {SharedUploadComponent} from './components/shared-upload/shared-upload.component';
import {LoaderComponent} from "./components/loader/loader.component";
import {LottieModule} from "ngx-lottie";
import { RentatranquilleFooterComponent } from './components/footers/rentatranquille/rentatranquille-footer.component';
import { GuestAdomFooterComponent } from './components/footers/guest-adom/guest-adom-footer.component';
import { KeylodgeFooterComponent } from './components/footers/keylodge/keylodge-footer.component';
import { FrenchconciergeFooterComponent } from './components/footers/frenchconcierge-footer/frenchconcierge-footer.component';
import { Yaago2YaagoFooterComponent } from '../modules/guest/yaago2-yaago-footer/yaago2-yaago-footer.component';
import { Yaago2PoweredByFooterComponent } from '../modules/guest/yaago2-poweredby-footer/yaago2-powered-by-footer.component';
import { GitesDeFranceComponent } from './components/footers/gites-de-france/gites-de-france.component';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { HaloconciergeComponent } from './components/footers/haloconcierge/haloconcierge.component';
import {Y3ExplainedButtonComponent} from './components/y3-explained-button/y3-explained-button.component';
import {TagComponent} from './components/tag/tag.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';



@NgModule({
  declarations: [ButtonComponent, SharedSnackbarComponent, GuestGalleryDetailComponent,
  AmenitiesCounterComponent,
    SharedUploadComponent,
  TooltipComponent,
  AudioPlayComponent,
  CotoonFooterComponent,
    ExternalVideoBoxComponent,
    QrCodeComponent,
    LoaderComponent,
    TabsMenuComponent,
    RentatranquilleFooterComponent,
    GuestAdomFooterComponent,
    KeylodgeFooterComponent,
    FrenchconciergeFooterComponent,
    Yaago2PoweredByFooterComponent,
    Yaago2YaagoFooterComponent,
    GitesDeFranceComponent,
    LeafletMapComponent,
    HaloconciergeComponent,
    TagComponent,
    Y3ExplainedButtonComponent
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        RouterModule,
        SharedDirectivesModule,
        ClipboardModule,
        LottieModule,
        SharedPipesModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        NgMultiSelectDropDownModule.forRoot(),
        PerfectScrollbarModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatBadgeModule,
        ButtonComponent,
        SharedSnackbarComponent,
        GuestGalleryDetailComponent,
        SharedUploadComponent,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedDirectivesModule,
        AmenitiesCounterComponent,
        TooltipComponent,
        ClipboardModule,
        AudioPlayComponent,
        CotoonFooterComponent,
        ExternalVideoBoxComponent,
        QrCodeComponent,
        TabsMenuComponent,
        LoaderComponent,
        RentatranquilleFooterComponent,
        GuestAdomFooterComponent,
        KeylodgeFooterComponent,
        FrenchconciergeFooterComponent,
        Yaago2PoweredByFooterComponent,
        Yaago2YaagoFooterComponent,
        GitesDeFranceComponent,
        LeafletMapComponent,
        HaloconciergeComponent,
        TagComponent,
        Y3ExplainedButtonComponent,
        NgMultiSelectDropDownModule
    ],
  entryComponents: [
    GuestGalleryDetailComponent
  ]
})
export class SharedModule { }
