<div class="m6-main-container" [ngStyle]="{'min-height':embedded ? 'unset': '100vh'}">

  <div *ngIf="!embedded" class="m6-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>

  <div *ngIf="!embedded" class="y3-flex-row y3-flex-justify-content-center y3-margin-bottom-32 ">
    <img *ngIf="corporate.logoUrl" class="m6-logo" [src]="corporate.logoUrl" alt="image" />
  </div>


  <div class="y3-margin-bottom-16">
    <div class="y3-High y3-bold y3-margin-top-16 y3-title-colors">{{'guest-view-booking-details.we-do-propose' | translate}}</div>
  </div>

  <div id="SECTION__HORIZONTAL_VIEW_ONLY" class="y3-flex-row y3-flex-align-center">
    <!--<button  appDebounceClick (debounceClick)="partnerScroll(upSellContainer, 'left')" class="fab-button previous" mat-mini-fab color="primary" aria-label="Previous">
      <mat-icon style="line-height: 24px;">arrow_back_ios_new</mat-icon>
    </button>-->

    <perfect-scrollbar class="y3-flex-row upSellContainer" #upSellContainer [config]="{swipeEasing:true, useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
      <div class="y3-flex-row " >
        <div class="m6-horizontal-card " *ngFor="let guide of upsellsGuides" (click)="showMobileGuideDetails(guide)" >
          <div class="ribbon-2" *ngIf="checkIfUpsellItemExists(guide.guideId)">{{ 'guest-guid-page.in-card' | translate}}</div>
          <div class="ribbon-3" *ngIf="checkUpSellState(guide.guideId)==='created'">{{ 'guest-guid-page.waiting' | translate}}</div>
          <div class="ribbon-4" *ngIf="checkUpSellState(guide.guideId)==='paid'">{{ 'guest-guid-page.paid' | translate}}</div>
          <div class="ribbon-4" *ngIf="checkUpSellState(guide.guideId)==='accepted'">{{ 'guest-guid-page.accepted' | translate}}</div>
          <div class="ribbon-5" *ngIf="checkUpSellState(guide.guideId)==='refused'">{{ 'guest-guid-page.refused' | translate}}</div>
          <img class="m6-horizontal-img" src="{{getGuideCover(guide)}}">
          <div class="y3-Small y3-padding-8 y3-bold y3-text-ellipsis-nowrap">
            {{guide.translations[0].title}}
          </div>
        </div>
      </div>


    </perfect-scrollbar>

    <!--<button  appDebounceClick (debounceClick)="partnerScroll(upSellContainer, 'right')" class="fab-button next" mat-mini-fab color="primary" aria-label="Next">
      <mat-icon style="line-height: 24px;">arrow_forward_ios</mat-icon>
    </button>-->

  </div>

  <!--<div class="y3-partner-section">
    <div class="new-upsale-card-container " #partnerContainer>

      <div class=" card-small flex y3-flex-column y3-margin-bottom-8" *ngFor="let guide of upsellsGuides" (click)="showMobileGuideDetails(guide)" >
        <div class="width-100 y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-clickable">
          <div class="y3-flex-row y3-flex-align-center">
            <div style="position: relative">
              <img class="category-cover y3-margin-right-12" src="{{getGuideCover(guide)}}" alt="">
            </div>

            <div style="position: relative" class="y3-flex-row">
              <div  class="y3-Small y3-bold y3-title-colors" >{{guide.translations[0].title |titlecase }}</div>

            </div>
          </div>
          <mat-icon class="m0-button-quick-2">chevron_right</mat-icon>
        </div>
      </div>
    </div>
  </div>-->

</div>
