<div style=" position: relative; overflow: auto; height: 100%" class="y3-full-width">
  <div class="m20-page-container">

    <img *ngIf="currentPictureUrl == null" class="m20-image-container" src="{{utilsService.getGuideCover(guide, corporate)}}">
    <img *ngIf="currentPictureUrl != null" class="m20-image-container" src="{{currentPictureUrl}}">
    <div class="m20-return-container">
      <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-center" >
          <div class="m20-mat" (click)="back()">arrow_back</div>
      </div>
    </div>

    <div class="m20-card-container">
      <div class="m20-card-content">

        <div *ngIf="guide?.upSellSection?.upSellToValidate" class="y3-margin-right-8 y3-margin-top-8 y3-full-width" style="width: fit-content; text-align: center ">
          <app-tag [padding]="'8px'" [sizeText2]="'12px'" [text]="'guest-guid-page.validation' | translate" [type]="'red'"></app-tag>
        </div>

        <div class="y3-bold y3-Large y3-margin-top-8">{{guide.translations[0].title}}</div>

        <div style="height: 150px;">
          <div class="y3-Small y3-color-scorpion y3-margin-top-8 m20-guide-description" [innerHTML]="guide.translations[0].description"></div>
        </div>

        <section id="SECTION____GALERIE____IMAGE" *ngIf="guide.documents != null && guide.documents?.length > 1" class="y3-margin-top-8">
          <div class="y3-bold y3-Small">{{'m22-guide-display.more-pictures' | translate}}</div>
          <perfect-scrollbar class="y3-flex-row" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
            <div class="y3-flex-row y3-flex-align-center y3-margin-top-16">
            <div *ngFor="let image of guide.documents" >
                <img (click)="choosePicture(image.url)" class="m20-image-size y3-clickable" src="{{image.url}}" >
            </div>
            </div>
          </perfect-scrollbar>
        </section>

        <div class="y3-flex-column y3-margin-top-8" *ngIf="guide?.externalLinks && guide?.externalLinks?.length > 0">
          <div class="y3-bold y3-Small">{{'guest-guid-page.external-links' | translate}} </div>
          <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-margin-top-8" *ngFor="let link of guide.externalLinks; let i = index">
            <span class="roboto y3-bold y3-Small underline clickable width-90 txt-nowrap txt-ellipsis hidden-overflow" (click)="openLink(link.url)">{{link.title}}</span>
          </div>
        </div>

      </div>


      <div class="m20-card-call-to-action" *ngIf="guide.upSellSection.upSellAmount == null && guide.upSellSection.stripePaymentUrl != null ">
        <div class="y3-flex-row y3-flex-justify-content-end " *ngIf="guide?.upSellSection?.showAsUpSell && guide.upSellSection.stripePaymentUrl" >
          <app-button [type]="'primary-small'" [text]="'guest-guid-page.pay'" (debounceClick)="openLink(guide.upSellSection.stripePaymentUrl)"></app-button>
        </div>
      </div>

      <div class="m20-card-call-to-action" *ngIf="guide.upSellSection.upSellAmount != null">

        <div class="y3-flex-row y3-margin-left-24 ">
          <div class="y3-flex-row y3-flex-justify-space-between y3-full-width">
            <div>

            </div>
            <div class="y3-High y3-roboto y3-bold">{{guide.upSellSection.upSellAmount.amount | currency: guide.upSellSection.upSellAmount.currency?.toUpperCase():'symbol':'1.2-2':'en'}}</div>
          </div>
        </div>

        <div *ngIf="checkIfUpsellItemExists(guide.guideId) && guide.upSellSection.needQty"  class="clickable" >
          <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">cancel</mat-icon>
        </div>

        <div class="squares-container" *ngIf="guide.upSellSection.needQty">
          <!--<p class="y3-Small">{{'guest-guid-page.qty' | translate}}</p>-->

          <div class="bulle-container">
            <div *ngIf="!checkIfUpsellItemExists(guide.guideId)" (click)="removeQty(guide)" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">do_not_disturb_on</mat-icon>
            </div>



            <div class="y3-High g11-value">
              <span>{{guide.upSellSection.relatedQty}}</span>
            </div>
            <div *ngIf="!checkIfUpsellItemExists(guide.guideId)" (click)="addQty(guide)" class="clickable" >
              <mat-icon style="font-size: 30px;" class="y3-color-secondary-button">add_circle</mat-icon>
            </div>
          </div>
        </div>

        <div class="y3-flex-row y3-flex-justify-content-end " *ngIf="currentUpSellOnBoarding == null">
          <div *ngIf="!checkIfUpsellItemExists(guide.guideId)">
            <app-button [type]="'primary-small'" [text]="'guest-guid-page.add-to-card'" (debounceClick)="addUpSellOnboarding(guide)"></app-button>
          </div>
          <div *ngIf="checkIfUpsellItemExists(guide.guideId)">
            <app-button [type]="'secondary-small'" [text]="'guest-guid-page.remove-from-card'" (debounceClick)="removeUpSellOnboarding(guide)"></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
